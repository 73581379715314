import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { GLOBAL } from "../../providers/global";
import * as firebase from 'firebase/app';
import 'firebase/firestore'
import { LoaderService } from '../../shared/loader.service';
import swal from 'sweetalert2';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';



@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent {
    // @ViewChild('f') loginForm: NgForm;
    userServer = this.global.baseURL == 'https://playwithvento.com' ? 'vento' : '';
    // userId = 'admin';
    // userPassword = 'admin';
    userId = '';
    userPassword = '';
    envServer = environment.production ? 'prod' : 'dev';
    ref = firebase.firestore().collection('connections').doc(this.envServer)

    constructor(public global:GLOBAL, 
        public http : Http,
        public loaderService : LoaderService) { }

    onCancel() {
        console.log('enter onCancel');
        this.loaderService.display(true);
        console.log('loader', this.loaderService)
        this.userId = '';
        this.userPassword = '';
        this.loaderService.display(false);
    }

    async getConnections(){
      let boards = [];
      await this.ref.get().then((querySnapshot) => {
        let data = querySnapshot.data()[this.userServer];
        if(data !== undefined) {
          for(var i = 0; i < data.length; i++){
              let item = data[i]
              boards.push({
                backendURL : item.backendURL,
                referrer   : item.referrer
            });
          }
        } else {
          boards.push({
            backendURL : '',
            referrer   : ''
          })
        }
        console.log("ini boards", boards)
        return boards
      });
      return boards

    }


    ngOnInit() {
      // this.global.user_server =  'vento_local';
      // this.global.my_api = 'https://cool.uruz.id/api/';
      // this.global.clientSetting = 'VENTO';
      // this.global.my_api_pic = 'http://localhost:3000/';
  
      this.global.user_server =  'vento';
      this.global.my_api = 'https://erp.estilosoft.com/api/';
      this.global.clientSetting = 'VENTO';
      // this.global.my_api_pic = 'http://pm.culinpos.com:3000/';
      
  
    }
  
    async onSave(){
        // this.global.showToast('success',"Berhasil","Anda berhasil masuk");
        // this.global.showLog('info','Enter onSave','');


        // let data = await this.getConnections()
        // let host = window.location.host;
        // for(var i = 0; i < data.length; i++){
        //     let item = data[i]
        //     if(item.referrer == host) {
        //         this.global.my_api = item.backendURL;
        //     }
        // }
        // if(this.global.my_api == '') {
        //   this.global.toastr.error("Akun tidak ditemukan dengan user server mana pun.");
        //   this.loaderService.display(false);
        //   return 
        // }


        this.global.user_server =  this.userServer;
        this.loaderService.display(true);
        if (this.global.user_server.length < 1) {
            this.global.toastr.error("Your Session Expired. please try to login again");
            return;
        }
        if (this.userId.length < 1) {
            this.global.toastr.error("ID Harus di isi.");
            return;
        }
    
        if (this.userPassword.length < 1) {
            this.global.toastr.error("Password harus diisi.");
            return;
        }

        window.localStorage.setItem('my_api',this.global.my_api);

        var sekarang = new Date().getTime();
        let link = this.global.my_api+'customers-v2/login/';
        // this.global.showLog('info','link',link);
        var headers = new Headers();        
        headers.append('Content-Type', 'application/json' );
        let options = new RequestOptions({ headers: headers });
        let body = {
          "from_origin" : this.global.fromOrigin,
          "userServer" : this.global.user_server,
          "userId" : this.userId,
          "userPassword" : this.userPassword,
        }
        this.global.showLog('info','body',body);
        this.http.post(link, body, options)
        // .timeout(8000)
        .subscribe(data => {
          this.loaderService.display(false);
        //   console.log('data',data);
          let response = JSON.parse(data['_body']);
          if (response.status == 'OK'){
              window.localStorage.setItem('my_api', this.global.my_api);
              window.localStorage.setItem('my_api_pic', this.global.my_api_pic);
              window.localStorage.setItem('login_time', response.data[0].login_time);
              window.localStorage.setItem('userServer', this.global.user_server);
              window.localStorage.setItem('userId', this.userId);
              window.localStorage.setItem('userToken', response.token);
              window.localStorage.setItem('userName', response.data[0].name);
              window.localStorage.setItem('userEmail', response.data[0].email);
              window.localStorage.setItem('userAddress', response.data[0].address);
              window.localStorage.setItem('userMobile', response.data[0].mobile);
              window.localStorage.setItem('userProvince', response.data[0].province);
              window.localStorage.setItem('userCity', response.data[0].city);
              window.localStorage.setItem('userCoa', response.data[0].coa);
              window.localStorage.setItem('userDisc', response.data[0].disc1);
              window.localStorage.setItem('clientSetting', JSON.stringify(response.data3));
              window.localStorage.setItem('userPriviledge', JSON.stringify(response.data2));
              this.global.userDisc = response.data[0].disc1;
              window.localStorage.setItem('userDiscCoa', response.data[0].discCoa);
              this.global.userDiscCoa = response.data[0].discCoa;
              console.log('this.global.userDiscCoa = response.data[0].discCoa;',this.global.userDiscCoa);

              window.localStorage.setItem('marketingId', response.data[0].marketing_id ? response.data[0].marketing_id : 'admin');
              window.localStorage.setItem('isLoggedIn', 'true');
              this.global.isLoggedIn = true;
              // window.localStorage.setItem('clientSetting', this.global.clientSetting);
              this.global.prepareClientSetting()
              this.global.goTo('content-layout');
          }
          else {
            this.global.toastr.error(response.message);
          }

        }, error => {
            this.loaderService.display(false);
            console.log('response error',error)
            this.global.toastr.error('error konek ke server');
            let response = JSON.parse(error['_body']);
            this.global.toastr.error(response.message);
        });
    }


}