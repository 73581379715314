import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GLOBAL } from "../../providers/global";
import { LoaderService } from '../../shared/loader.service';
import swal from 'sweetalert2';
import { Http, Headers, RequestOptions } from '@angular/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {

  rows = [];
  mode = 'view';

  filter = {
    search1 : '',
    search_col1 : 'all',
  
    search2 : '',
    search_col2 : '',
  
    sort_col1 : 'id',
    sort_dir1 : 'asc',
  
    sort_col2 : 'name',
    sort_dir2 : 'asc',
    
    jumlah_baris : 50,
    total_baris : 0,
    aktif : 'all',
    halaman : 1,
  };

  detailData = {
    id : '',
    name : '',
    password : '',
    active : 0
  }

  image : any;
  constructor(public global:GLOBAL, 
    public http : Http,
    public loaderService : LoaderService) { }

  ngOnInit() {
      this.mode='view';
      if (window.localStorage.getItem('user_filter')){
        this.filter = JSON.parse(window.localStorage.getItem('user_filter'));
      }

      this.pageCallback({count :0,pageSize:0, limit:0, offset:-1});
  }

  onKeyUp(event: any){
    console.log(event.keyCode);
    if (event.keyCode == 13){
      this.refresh()
    }
    else if (event.keyCode == 27) {
      this.filter.search1 = '';
    }
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.filter.halaman = pageInfo.offset+1;
    this.refresh();
  }

  refresh(){
    this.rows = [];

    window.localStorage.setItem('user_filter',JSON.stringify(this.filter));

    this.loaderService.display(true);
    // this.global.showLog('info','masuk','refresh user');
    let link = this.global.my_api+'users';
    this.global.showLog('info','link',link);
    var headers = new Headers();        
    headers.append('Content-Type', 'application/json' );
    // headers.append('x-access-token', window.localStorage.getItem('userToken') );
    let options = new RequestOptions({ headers: headers });
    let body = {
      "from_origin" : '*',
      // "userServer" : window.localStorage.getItem('userServer'),
      "userId" : window.localStorage.getItem('userId'),
      "filter" : JSON.stringify(this.filter),

    }
    this.global.showLog('info','body',body);
    this.http.post(link, body, options)
    .subscribe(data => {
      this.loaderService.display(false);
      let response = JSON.parse(data['_body']);
      this.global.showLog('info','response',response);
      if (response.status == 'OK'){
        this.global.showLog('info','refresh user','ok');
        this.filter.total_baris = response.total_baris;
        this.rows = response.data;
      }
      else {
        this.global.showLog('error','refresh user',response);
        this.global.showError('refresh','00',response.message);        
      }

    }, error => {
        this.loaderService.display(false);
        this.global.showLog('error','error',error);
        let response = JSON.parse(error['_body']);
        this.global.showError('refresh',response.status,response.message);
        if (error.status == 401){
          this.global.logout();
        }
    });
  }

  onCancel(){
    this.mode = 'view';
    this.detailData = {
      id : '',
      name : '',
      password : '',
      active : 0
    }
  }

  onSave(){
    if (this.detailData.id.length < 1){
      this.global.toastr.error("ID Harus diisi","BELUM BISA DI SIMPAN");
      return;
    }
    if (this.detailData.name.length < 1){
      this.global.toastr.error("Nama Harus diisi","BELUM BISA DI SIMPAN");
      return;
    }
    if (this.detailData.password.length < 1){
      this.global.toastr.error("Password Harus diisi","BELUM BISA DI SIMPAN");
      return;
    }

        this.loaderService.display(true);
        // this.global.showLog('info','masuk','save new user');
        let link = this.global.my_api+'users/save';
        // this.global.showLog('info','link',link);
        var headers = new Headers();        
        headers.append('Content-Type', 'application/json' );
        // headers.append('x-access-token', window.localStorage.getItem('userToken') );
        let options = new RequestOptions({ headers: headers });
        
        // let formData = new FormData();
        // formData.append( "from_origin" , this.global.fromOrigin);
        // formData.append( "userServer" , window.localStorage.getItem('userServer'));
        // formData.append( "userId" , window.localStorage.getItem('userId'));
        // formData.append( "mode" , this.mode);
        // formData.append( "detailData" , JSON.stringify(this.detailData));
        // formData.append( "image" , this.image);
        // let body = formData;
    
        let body = {
          "from_origin" : '*',
          // "userServer" : window.localStorage.getItem('userServer'),
          "userId" : window.localStorage.getItem('userId'),
    
          "mode" : this.mode,
          "detailData" : JSON.stringify(this.detailData),
        }
        this.global.showLog('info','body',body);
        this.http.post(link, body, options)
        .subscribe(data => {
          this.loaderService.display(false);
          let response = JSON.parse(data['_body']);
          if (response.status == 'OK'){
            this.global.showLog('info','save new user','ok');
            this.onCancel();
            this.refresh();
          }
          else {
            this.global.showLog('error','save new user',response);
            this.global.showError('save new','00',response.message);        
          }
    
        }, error => {
            this.loaderService.display(false);
            console.log('error sini',error);
            let response = JSON.parse(error['_body']);
            this.global.showError('save new',error.status,response.message);        
            if (error.status == 401){
              this.global.forceLogout();
            }
        });   
  }

  addNew(){
    this.mode = 'new';
    this.detailData = {
      id : '',
      name : '',
      password : '',
      active : 1
    }
  }

  edit(i){
    this.loaderService.display(true);
    // this.global.showLog('info','masuk','edit user');
    let link = this.global.my_api+'users/get1';
    this.global.showLog('info','link',link);
    var headers = new Headers();        
    headers.append('Content-Type', 'application/json' );
    // headers.append('x-access-token', window.localStorage.getItem('userToken') );
    let options = new RequestOptions({ headers: headers });
    let body = {
      "from_origin" : '*',
      // "userServer" : window.localStorage.getItem('userServer'),
      "userId" : window.localStorage.getItem('userId'),
      "id":this.rows[i].id
    }
    this.global.showLog('info','body',body);
    this.http.post(link, body, options)
    .subscribe(data => {
      this.loaderService.display(false);
      let response = JSON.parse(data['_body']);
      if (response.status == 'OK'){
        this.global.showLog('info','get user','ok');
        console.log('data',response.data);
        this.detailData = response.data[0];
        this.mode = 'edit';
      }
      else {
        this.global.showLog('error','error get user',response);
        this.global.showError('edit get','00',response.message);        
      }

    }, error => {
        this.loaderService.display(false);
        console.log(error);
        let response = JSON.parse(error['_body']);
        this.global.showError('edit get',error.status,response.message);        
        if (error.status == 401){
          this.global.forceLogout();
        }
    });       
  }

  delete(i){
    swal({
      title: 'KONFIRMASI',
      text: "Yakin mau hapus user "+this.rows[i].id+" "+this.rows[i].name+" ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        this.loaderService.display(true);
        this.global.showLog('info','masuk','delete user');
        let link = this.global.my_api+'users/del';
        this.global.showLog('info','link',link);
        var headers = new Headers();        
        headers.append('Content-Type', 'application/json' );
        // headers.append('x-access-token', window.localStorage.getItem('userToken') );
        let options = new RequestOptions({ headers: headers });
        let body = {
          "from_origin" : '*',
          "userServer" : window.localStorage.getItem('userServer'),
          "userId" : window.localStorage.getItem('userId'),
          "id":this.rows[i].id
        }
        this.global.showLog('info','body',body);
        this.http.post(link, body, options)
        .subscribe(data => {
          this.loaderService.display(false);
          let response = JSON.parse(data['_body']);
          if (response.status == 'OK'){
            this.global.showLog('info','delete user','ok');
            console.log('data',response.data);
            this.global.toastr.info(response.message);
            this.refresh();
          }
          else {
            this.global.showLog('error','error delete user',response);
            this.global.showError('delete','00',response.message);        
          }
    
        }, error => {
            this.loaderService.display(false);
            console.log(error);
            let response = JSON.parse(error['_body']);
            this.global.showError('delete',error.status,response.message);        
            if (error.status == 401){
              this.global.forceLogout();
            }
        }); 
      }
    })    

  }

  getRowClass = (row) => {
    return {
      'row-color': row.active == 0
    };
  }
}
