import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { GLOBAL } from "../../providers/global";
import { LoaderService } from '../../shared/loader.service';
import swal from 'sweetalert2';
import { Http, Headers, RequestOptions } from '@angular/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-my-transaction',
  templateUrl: './my-transaction.component.html',
  styleUrls: ['./my-transaction.component.scss']
})
export class MyTransactionComponent implements OnInit {

  isRunning = false;
  rows = [];
  from_date : any;
  till_date : any;
  mode = 'view-sum';
  filter = {
    search1 : '',
  
    jumlah_baris : 200,
    total : 0,
    grand_total : 0,

    total_diskon : 0,
    grand_total_diskon : 0,

    total_nett : 0,
    grand_total_nett : 0,

    total_baris : 0,

    halaman : 1,
  };

  detailData = [];
  detailTrans = [];

  localURL : any;
  newPhoto = false;
  image : any;

  id_selected = '';
  code_selected = '';

  constructor(public global:GLOBAL, 
    public http : Http,
    private modalService: NgbModal,
    public loaderService : LoaderService) { }


  setToday() {
    var sekarang= new Date();
    this.from_date = {year: sekarang.getFullYear(), month: sekarang.getMonth() + 1, day: 1};
    this.till_date = {year: sekarang.getFullYear(), month: sekarang.getMonth() + 1, day: sekarang.getDate()};
    this.global.showLog('info','setToday from',this.from_date);
    this.global.showLog('info','setToday till',this.till_date);
  } 

  ngOnInit() {
      this.setToday();
      this.mode='view-sum';
      if (window.localStorage.getItem('trans_s_si_filter')){
        this.filter = JSON.parse(window.localStorage.getItem('trans_s_si_filter'));
      }

      if (window.localStorage.getItem('trans_s_si_filter_from_date')){
        this.from_date = JSON.parse(window.localStorage.getItem('trans_s_si_filter_from_date'));
        this.global.showLog('info','setToday local from',this.from_date);
      }

      if (window.localStorage.getItem('trans_s_si_filter_till_date')){
        this.till_date = JSON.parse(window.localStorage.getItem('trans_s_si_filter_till_date'));
        this.global.showLog('info','setToday local till',this.till_date);
      }

      this.global.onRefresh();
      this.refresh('new');
  }

  readFile(i,e){
    console.log('filenya '+i,e);
    console.log('file size ',e.target.files[0].size);
    if (  i == '1'){
      var ukuran = e.target.files[0].size;
      if (ukuran < this.global.maxPhotoSize) {
        var reader = new FileReader();
        var f = e.target.files;
        reader.onloadend = (snap=> {
            // console.log(reader.result);
            this.localURL = reader.result;
            this.image = e.target.files[0];
        })
        reader.readAsDataURL(f[0]);
        this.newPhoto = true;
        
      }
      else {
        this.global.showError('Ukuran Foto','00','Ukuran gambar harus lebih kecil dr '+this.global.maxPhotoSizeInfo);
      }
    }

  }

  clearPhoto(x){
    if (x == '1'){
      this.localURL = '';
      this.newPhoto = false;
    }
  }

  onUpload(x,img,flag,asli):Promise<any>{
    return new Promise ((resolve,reject)=>{
      let _formData = new FormData();
      _formData.append( "userServer" , window.localStorage.getItem('userServer'));
      _formData.append( "userId" , window.localStorage.getItem('userId'));

      _formData.append("fileName", this.id_selected+'.jpg');
      _formData.append("dir", 'trans_s_si');
      _formData.append("image", img);
      let body = _formData;
      let headers = new Headers();
      headers.append('x-access-token', window.localStorage.getItem('userToken') );
      let options = new RequestOptions({ headers: headers });
  
      if (flag){
        this.http.post(this.global.my_api+'upload', body, options)
        .subscribe((data) => {
          this.global.showLog('info','data uploaded',data);
          resolve(JSON.parse(data['_body']).data);
        }, error => {
          console.log('error',error);
          reject(error);
        });
      }
      else {
        resolve(asli);
      }
    })
  }

  cancelUpload(){
    this.mode = 'view-sum';
    this.id_selected = '';
    this.code_selected = '';
  }

  uploadPhoto(){
    if (this.newPhoto){
      this.onUpload('1',this.image,this.newPhoto,'uploads')
      .then(hasil=>{
        console.log('selesai upload',hasil);
        this.global.toastr.info('bukti transfer sudah di upload');
        this.mode = 'view-sum';
      })
    }
  }

  refresh(stat){
    if (stat == 'new') {
      this.filter = {
        search1 : this.filter.search1,
    
        jumlah_baris : 200,
        total : 0,
        grand_total : 0,

        total_diskon : 0,
        grand_total_diskon : 0,
    
        total_nett : 0,
        grand_total_nett : 0,
    
    
        total_baris : 0,
    
        halaman : 1,  
      };    

      this.rows = [];
    }
    else {
      this.filter.halaman++;
    }

    this.mode = 'view-sum';
    // this.rows = [];
    var tgl_awal = this.from_date.year+"-"+this.from_date.month+"-"+this.from_date.day+" 00:00:00";
    var tgl_akhir = this.till_date.year+"-"+this.till_date.month+"-"+this.till_date.day+" 23:59:59";

    // console.log('tgl', tgl_awal+" s/d "+tgl_akhir);
    
    window.localStorage.setItem('trans_s_si_filter',JSON.stringify(this.filter));
    window.localStorage.setItem('trans_s_si_filter_from_date',JSON.stringify(this.from_date));
    window.localStorage.setItem('trans_s_si_filter_till_date',JSON.stringify(this.till_date));

    var jumlah_baris_temp = this.filter.jumlah_baris;

    this.loaderService.display(true);
    // this.global.showLog('info','refresh','refresh');
    let link = this.global.my_api+'trans-s-si/refresh';
    // this.global.showLog('info','link',link);
    var headers = new Headers();        
    headers.append('Content-Type', 'application/json' );
    headers.append('x-access-token', window.localStorage.getItem('userToken') );
    let options = new RequestOptions({ headers: headers });
    let body = {
      "from_origin" : this.global.fromOrigin,
      "userServer" : window.localStorage.getItem('userServer'),
      "userId" : window.localStorage.getItem('userId'),

      "tgl_awal" : tgl_awal,
      "tgl_akhir" : tgl_akhir,
      "is_customer" : 1,

      "filter" : JSON.stringify(this.filter)

    }
    // this.global.showLog('info','body',body);
    this.http.post(link, body, options)
    .subscribe(data => {
      this.loaderService.display(false);
      let response = JSON.parse(data['_body']);
      this.global.showLog('info','response',response);
      if (response.status == 'OK'){
        // this.global.showLog('info','refresh','ok');
          if (this.filter.halaman == 1) {
            this.filter.total_baris = response.total_baris;
            this.filter.grand_total = response.grand_total;
            this.filter.grand_total_diskon = response.grand_total_diskon;
            this.filter.grand_total_nett = response.grand_total_nett;
            this.filter.total = 0;
            this.filter.total_diskon = 0;
            this.filter.total_nett = 0;
            this.rows = [];
          }
          if (response.data.length == 0 ){
            this.global.toastr.info("sudah tidak ada data lagi");
          }
          else {
            response.data.forEach(bar => {
              let tt = bar;
              tt['picpath_thumb'] = this.global.my_api_pic+tt['picpath_thumb'];
              tt['picpath'] = this.global.my_api_pic+tt['picpath'];
              this.rows.push(tt);
              this.filter.total += bar.total;              
              this.filter.total_diskon += bar.total_diskon;              
              this.filter.total_nett += bar.total_nett;              
            });
          }
      }
      else {
        this.global.showLog('error','refresh',response);
        this.global.showError('Refresh Data','00',response.message);
      }

    }, error => {
        this.loaderService.display(false);
        this.global.showLog('error','error',error);
        let response = JSON.parse(error['_body']);
        this.global.showError('refresh',response.status,response.message);
        if (error.status == 401){
          this.global.forceLogout();
        }
    });
  }

  view(i){
    if (i.length > 20){
      var id_to_send = i;
    }
    else {
      var id_to_send = this.rows[i].id;
    }

      this.mode='view';
      this.loaderService.display(true);
      this.global.showLog('info','masuk edit','edit');
      let link = this.global.my_api+'trans-s-si/get1';
  
      var headers = new Headers();        
      headers.append('Content-Type', 'application/json' );
      headers.append('x-access-token', window.localStorage.getItem('userToken') );
      let options = new RequestOptions({ headers: headers });
      let body = {
        "from_origin" : this.global.fromOrigin,
        "userServer" : window.localStorage.getItem('userServer'),
        "userId" : window.localStorage.getItem('userId'),
        "id": id_to_send,
        "mode":this.mode,
        "override" : false,
        "is_customer" : 1
      }
      // this.global.showLog('info','body',body);
      this.http.post(link, body, options)
      .subscribe(data => {
        this.loaderService.display(false);
        let response = JSON.parse(data['_body']);
        console.log('response',response);
        if (response.status == 'OK'){
          this.detailData = response.dataMaster[0];
          console.log('detailDataEdit',this.detailData);
          this.detailTrans = response.dataDetail;
          // console.log('detailtrans',this.detailTrans);
          this.detailTrans = [...this.detailTrans];
          // this.hitungTotal();
        }
        else {
          this.global.showLog('error','error get',response);
          this.global.showError('Get Detail Data','00',response.message);
          this.onCancel();
        }
  
      }, error => {
          this.loaderService.display(false);
          let response = JSON.parse(error['_body']);
          if (error.status == 401){
            this.global.forceLogout();
          }
      }); 
  }

  showUpload(i){
    this.id_selected = this.rows[i].id;
    this.code_selected = this.rows[i].code;
    this.mode = 'showUpload';
    console.log('showup '+this.mode, this.id_selected);
    this.localURL = this.rows[i]['picpath'];
  }

  onCancel(){
    this.mode = 'view-sum';
  }

  // hitungTotal():Promise<any>{
  //   console.log('masuk hitung total');
  //   return new Promise ((resolve,reject)=>{
  //     try {
  //       this.total = 0;
  //       this.detailTrans.forEach(baris=>{
  //         this.total += baris.qty * baris.price;
  //         // this.total_weight += baris.qty * baris.weight_packing;
  //         // this.total_dimension += baris.qty * baris.length_packing * baris.width_packing * baris.height_packing;
  //       });

  //       resolve(true);
  //     }
  //     catch (error){
  //       reject(error);
  //     }
  //   })
  // }  
}
