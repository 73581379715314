import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { GLOBAL } from "../../providers/global";
import { LoaderService } from '../../shared/loader.service';
import swal from 'sweetalert2';
import { Http, Headers, RequestOptions } from '@angular/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { buffer } from 'rxjs/operator/buffer';
import { resolve } from 'q';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @ViewChild('myInput')
  myInputVariable: ElementRef;

  rows = [];
  selected = [];
  statusImport = []
  isRowSelected = false;

  localURL1 : any;
  newPhoto1 = false;
  image1 : any;

  from_date : any;
  till_date : any;

  mode = 'view';

  filter = {
    // branch_id : 'semua',

    search1 : '',
    search_col1 : 'all',
  
    search2 : '',
    search_col2 : 'code',
  
  
    sort_col1 : 'updated_date',
    sort_dir1 : 'desc',
  
    sort_col2 : 'id',
    sort_dir2 : 'asc',
  
    jumlah_baris : 50,
    total_baris : 0,
    aktif : 'all',
    halaman : 1,

    kolom_tanggal : 'none',
  };

  detailData = {
    id : '',
    code : '',
    name : '',
    description : '',
    kubikasi : '',
    note : '',
    price : 0,
    price_buy : 0,

    picpath1 : '-',
    picpath1_thumb : '-',
    created_by : '',
    created_by_name : '',
    created_date : '',

    updated_by : '-',
    updated_by_name : '',
    updated_date : '',

  }

  constructor(public global:GLOBAL, 
    public http : Http,
    public loaderService : LoaderService) { }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    console.log('in export');
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'rows': worksheet }, SheetNames: ['rows'] };
    const excelBuffer: any = XLSX.writeFile(workbook,excelFileName+'.xlsx');
  }
  
  
  ayoExport(){
    this.exportAsExcelFile(this.rows,'data');
  }   

  importFromExcel(evt): void {
    this.statusImport=[];
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      this.global.showError('Pilih file','00', 'hanya boleh pilih 1 file aja');
      throw new Error('Cannot use multiple files');
    }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      //   this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
      var dataToImport = XLSX.utils.sheet_to_json(ws, {header: 1});
      this.global.showLog('info','data to import',dataToImport);
      if (dataToImport[0][0] !== 'KODE'){
        this.global.showError('Salah Format','00','Kolom A1 harus diisi dengan KODE');
        return;
      } 
      else if (dataToImport[0][1] !== 'NAMA'){
        this.global.showError('Salah Format','00','Kolom B1 harus diisi dengan NAMA');
        return;
      } 
      else if (dataToImport[0][2] !== 'DESKRIPSI'){
        this.global.showError('Salah Format','00','Kolom C1 harus diisi dengan DESKRIPSI');
        return;
      } 
      else if (dataToImport[0][3] !== 'KUBIKASI'){
        this.global.showError('Salah Format','00','Kolom D1 harus diisi dengan KUBIKASI');
        return;
      } 
      else if (dataToImport[0][4] !== 'HARGA BELI'){
        this.global.showError('Salah Format','00','Kolom E1 harus diisi dengan HARGA BELI');
        return;
      } 
      else if (dataToImport[0][5] !== 'HARGA JUAL'){
        this.global.showError('Salah Format','00','Kolom F1 harus diisi dengan HARGA JUAL');
        return;
      } 
      else if (dataToImport[0][6] !== 'CATATAN'){
        this.global.showError('Salah Format','00','Kolom G1 harus diisi dengan CATATAN');
        return;
      } 
        for (var i=1; i <dataToImport.length; i++){
          var baris = dataToImport[i];  
          // console.log('baris',baris);
          this.detailData = {
            id : '',
            code : baris[0],
            name : baris[1],
            description : baris[2],
            kubikasi : baris[3],
            price_buy : baris[4],
            price : baris[5],
            note : baris[6],
        
            picpath1 : '-',
            picpath1_thumb : '-',
            created_by : '',
            created_by_name : '',
            created_date : '',
        
            updated_by : '-',
            updated_by_name : '',
            updated_date : '',
          }
      
          this.loaderService.display(true);
          this.global.showLog('info','masuk','save new');
          let link = this.global.my_api+'products/import';
          this.global.showLog('info','link',link);
          var headers = new Headers();        
          headers.append('Content-Type', 'application/json' );
          let options = new RequestOptions({ headers: headers });
              
          let body = {
            "from_origin" : '*',
            // "userServer" : window.localStorage.getItem('userServer'),
            "userId" : window.localStorage.getItem('userId'),
      
            // "mode" : 'new',
            "detailData" : JSON.stringify(this.detailData),
        
          }
          this.global.showLog('info','body',body);
          this.http.post(link, body, options)
          .subscribe(data => {
            this.loaderService.display(false);
            let response = JSON.parse(data['_body']);
            if (response.status == 'OK'){
              this.statusImport.push({
                status : 'OK',
                message : response.message
              })
            }
            else {
              this.global.showLog('error','import',response);
              this.statusImport.push({
                status : 'NOT OK',
                message : response.message
              })
            }
      
          }, error => {
              this.loaderService.display(false);
              let response = JSON.parse(error['_body']);
              this.statusImport.push({
                status : 'NOT OK',
                message : response.message
              })
          });  
        }

    };

    reader.readAsBinaryString(target.files[0]);
    this.myInputVariable.nativeElement.value = "";
  } 


  setToday() {
    var sekarang= new Date();
    this.from_date = {year: sekarang.getFullYear(), month: sekarang.getMonth() + 1, day: sekarang.getDate()};
    this.till_date = {year: sekarang.getFullYear(), month: sekarang.getMonth() + 1, day: sekarang.getDate()};
    this.global.showLog('info','setToday from',this.from_date);
    this.global.showLog('info','setToday till',this.till_date);

  }

  onKeyUp(event: any){
    console.log(event.keyCode);
    if (event.keyCode == 13){
      this.refresh()
    }
    else if (event.keyCode == 27) {
      this.filter.search1 = '';
    }
  }

  ngOnInit() {
      this.setToday();
      this.mode='view';
      if (window.localStorage.getItem('product_filter')){
        this.filter = JSON.parse(window.localStorage.getItem('product_filter'));
      }

      if (window.localStorage.getItem('product_filter_from_date')){
        this.from_date = JSON.parse(window.localStorage.getItem('product_filter_from_date'));
        this.global.showLog('info','setToday local from',this.from_date);
      }

      if (window.localStorage.getItem('product_filter_till_date')){
        this.till_date = JSON.parse(window.localStorage.getItem('product_filter_till_date'));
        this.global.showLog('info','setToday local till',this.till_date);
      }

      this.pageCallback({count :0,pageSize:0, limit:0, offset:-1});
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.filter.halaman = pageInfo.offset+1;
    this.refresh();
  }

  refresh(){
    this.rows = [];
    this.statusImport = [];
    var tgl_awal = this.from_date.year+"-"+this.from_date.month+"-"+this.from_date.day+" 00:00:00";
    var tgl_akhir = this.till_date.year+"-"+this.till_date.month+"-"+this.till_date.day+" 23:59:59";

    console.log('tgl', tgl_awal+" s/d "+tgl_akhir);
    
    window.localStorage.setItem('product_filter',JSON.stringify(this.filter));
    window.localStorage.setItem('product_filter_from_date',JSON.stringify(this.from_date));
    window.localStorage.setItem('product_filter_till_date',JSON.stringify(this.till_date));

    this.loaderService.display(true);
    this.global.showLog('info','masuk','refresh');
    let link = this.global.my_api+'products';
    this.global.showLog('info','link',link);
    var headers = new Headers();        
    headers.append('Content-Type', 'application/json' );
    let options = new RequestOptions({ headers: headers });
    let body = {
      "from_origin" : '*',
      "userId" : window.localStorage.getItem('userId'),

      "tgl_awal" : tgl_awal,
      "tgl_akhir" : tgl_akhir,

      "filter" : JSON.stringify(this.filter)

    }
    this.global.showLog('info','body',body);
    this.http.post(link, body, options)
    .subscribe(data => {
      this.loaderService.display(false);
      this.loaderService.display(false);
      let response = JSON.parse(data['_body']);
      this.global.showLog('info','response',response);
      if (response.status == 'OK'){
        this.global.showLog('info','refresh','ok');
        this.filter.total_baris = response.total_baris;
        this.rows = response.data;
      }
      else {
        this.global.showLog('error','refresh',response);
        this.global.showError('refresh','00',response.message);
      }

    }, error => {
        this.loaderService.display(false);
        this.global.showLog('error','error',error);
        let response = JSON.parse(error['_body']);
        this.global.showError('refresh',response.status,response.message);
        if (error.status == 401){
          this.global.forceLogout();
        }
    });
  }


  onCancel(){
    this.mode = 'view';
    this.detailData = {
      id : '',
      code : '',
      name : '',
      description : '',
      kubikasi : '',
      note : '',
      price : 0,
      price_buy : 0,
  
      picpath1 : '-',
      picpath1_thumb : '-',
      created_by : '',
      created_by_name : '',
      created_date : '',
  
      updated_by : '-',
      updated_by_name : '',
      updated_date : ''
    }
  }

  readFile(i,e){
    console.log('filenya '+i,e);
    console.log('file size ',e.target.files[0].size);
    if (  i == '1'){
      var ukuran = e.target.files[0].size;
      if (ukuran < this.global.maxPhotoSize) {
        var reader = new FileReader();
        var f = e.target.files;
        reader.onloadend = (snap=> {
            // console.log(reader.result);
            this.localURL1 = reader.result;
            this.image1 = e.target.files[0];
        })
        reader.readAsDataURL(f[0]);
        this.newPhoto1 = true;
      }
      else {
        this.global.showError('kebesaran','00','Ukuran gambar harus lebih kecil dr '+this.global.maxPhotoSizeInfo);
      }
    }
    // else if ( i == '2'){
    //   var ukuran = e.target.files[0].size;
    //   if (ukuran < this.global.maxPhotoSize) {
    //     var reader = new FileReader();
    //     var f = e.target.files;
    //     reader.onloadend = (snap=> {
    //         // console.log(reader.result);
    //         this.localURL2 = reader.result;
    //         this.image2 = e.target.files[0];
    //     })
    //     reader.readAsDataURL(f[0]);
    //     this.newPhoto2 = true;
    //   }
    //   else {
    //     this.global.showError('kebesaran','00','Ukuran gambar harus lebih kecil dr '+this.global.maxPhotoSizeInfo);
    //   }
    // }

  }

  clearPhoto(x){
    if (x == '1') {
      this.localURL1 = '';
      this.detailData.picpath1 = '-';
      this.detailData.picpath1_thumb = '-';
    }
    // else if (x == '2'){
    //   this.localURL2 = '';
    //   this.detailData.picpath2 = '-';
    //   this.detailData.picpath2_thumb = '-';
    // }
  }

  /* Now send your form using FormData */
  onUpload(x,img,flag,asli):Promise<any>{
    return new Promise ((resolve,reject)=>{
      let _formData = new FormData();
      // _formData.append( "userServer" , window.localStorage.getItem('userServer'));
      _formData.append( "userId" , window.localStorage.getItem('userId'));

      _formData.append("fileName", this.detailData.id+'-'+this.detailData.code+'_'+x+'.jpg');
      _formData.append("dir", 'products');
      _formData.append("image", img);
      let body = _formData;
      let headers = new Headers();
      headers.append('x-access-token', window.localStorage.getItem('userToken') );
      let options = new RequestOptions({ headers: headers });
  
      if (flag){
        this.http.post(this.global.my_api+'upload', body, options)
        .subscribe((data) => {
          this.global.showLog('info','data uploaded',data);
          resolve(JSON.parse(data['_body']).data);
        }, error => {
          console.log('error',error);
          reject(error);
        });
      }
      else {
        resolve(asli);
      }
    })
  }

  onSave(){
    if (this.detailData.code.length < 1){
      this.global.toastr.error("Id harus di isi","BELUM BISA DI SIMPAN");
      return;
    }

    if (this.detailData.name.length < 1){
      this.global.toastr.error("Nama harus di isi","BELUM BISA DI SIMPAN");
      return;
    }
    this.loaderService.display(true);
    // upload foto

    var promises=[];
    promises.push(this.onUpload('1',this.image1,this.newPhoto1,this.detailData.picpath1));
    // promises.push(this.onUpload('2',this.image2,this.newPhoto2,this.detailData.picpath2));
    Promise.all(promises)
    .then(data=>{
      this.detailData.picpath1 = data[0];
      this.detailData.picpath1_thumb = data[0]+'_thumb.jpg';

      // this.detailData.picpath2 = data[1];
      // this.detailData.picpath2_thumb = data[1]+'_thumb.jpg';

      this.global.showLog('info','masuk','save new');
      let link = this.global.my_api+'products/save';
      this.global.showLog('info','link',link);
      var headers = new Headers();        
      headers.append('Content-Type', 'application/json' );
      let options = new RequestOptions({ headers: headers });
          
      let body = {
        "from_origin" : '*',
        "userId" : window.localStorage.getItem('userId'),
  
        "mode" : this.mode,
        "detailData" : JSON.stringify(this.detailData),
      }
      this.global.showLog('info','body',body);
      this.http.post(link, body, options)
      .subscribe(data => {
        this.loaderService.display(false);
        let response = JSON.parse(data['_body']);
        if (response.status == 'OK'){
          this.global.showLog('info','save new','ok');
          this.onCancel();
          this.refresh();
        }
        else {
          this.global.showLog('error','save new',response);
          this.global.showError('save new','00',response.message);
        }
  
      }, error => {
          this.loaderService.display(false);
          console.log('error sini',error);
          let response = JSON.parse(error['_body']);
          this.global.showError('save new',error.status,response.message);
          if (error.status == 401){
            this.global.forceLogout();
          }
      });  

    })
    // upload foto
    .catch(error=>{
      this.loaderService.display(false);
      this.global.showLog('error','error upload foto all',error);
      let response = JSON.parse(error['_body']);
      this.global.showError('save new catch','00',response.message);
    }) 
  }

  addNew(){

    this.mode = 'new';
    this.detailData = {
      id : '',
      code : '',
      name : '',
      description : '',
      kubikasi : '',
      note : '',
      price : 0,
      price_buy : 0,
  
      picpath1 : '-',
      picpath1_thumb : '-',
      created_by : '',
      created_by_name : '',
      created_date : '',
  
      updated_by : '-',
      updated_by_name : '',
      updated_date : ''

    }  
    this.localURL1 = '';
    this.detailData.picpath1 = '-';
    this.detailData.picpath1_thumb = '-';

  }


  edit(i){
    // if (this.selected.length == 0){
    //   this.global.toastr.error("Belum ada baris data yang di pilih","PERHATIAN");
    //   return;
    // }
    this.loaderService.display(true);
    this.global.showLog('info','masuk','edit');
    let link = this.global.my_api+'products/get1';
    this.global.showLog('info','link',link);
    var headers = new Headers();        
    headers.append('Content-Type', 'application/json' );
    let options = new RequestOptions({ headers: headers });
    let body = {
      "from_origin" : '*',
      "userServer" : window.localStorage.getItem('userServer'),
      "userId" : window.localStorage.getItem('userId'),
      "id":this.rows[i].id,
    }
    this.global.showLog('info','body',body);
    this.http.post(link, body, options)
    .subscribe(data => {
      this.loaderService.display(false);
      let response = JSON.parse(data['_body']);
      if (response.status == 'OK'){
        this.detailData = response.data[0];
        this.global.showLog('info','get',this.detailData);
        this.mode = 'edit';
        this.localURL1 = this.global.my_api+this.detailData.picpath1+'?d='+new Date().getTime();
        // this.localURL2 = this.global.my_api+this.detailData.picpath2+'?d='+new Date().getTime();
      }
      else {
        this.global.showLog('error','error get',response);
        this.global.showError('edit get','00',response.message);
      }

    }, error => {
        this.loaderService.display(false);
        console.log(error);
        let response = JSON.parse(error['_body']);
        this.global.showError('edit get',error.status,response.message);
        if (error.status == 401){
          this.global.forceLogout();
        }

    });       
  }


  delete(i){
    swal({
      title: 'KONFIRMASI',
      text: "Yakin mau hapus "+this.rows[i].id+" ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        this.loaderService.display(true);
        this.global.showLog('info','masuk','delete');
        let link = this.global.my_api+'products/del';
        this.global.showLog('info','link',link);
        var headers = new Headers();        
        headers.append('Content-Type', 'application/json' );
        let options = new RequestOptions({ headers: headers });
        let body = {
          "from_origin" : '*',
          "userServer" : window.localStorage.getItem('userServer'),
          "userId" : window.localStorage.getItem('userId'),
          "id":this.rows[i].id
        }
        this.global.showLog('info','body',body);
        this.http.post(link, body, options)
        .subscribe(data => {
          this.loaderService.display(false);
          let response = JSON.parse(data['_body']);
          if (response.status == 'OK'){
            this.global.showLog('info','delete','ok');
            console.log('data',response.data);
            this.global.toastr.info(response.message);
            this.refresh();
          }
          else {
            this.global.showLog('error','error delete',response);
            this.global.showError('delete','00',response.message);
          }
    
        }, error => {
            this.loaderService.display(false);
            console.log('masuk error',error);
            let response = JSON.parse(error['_body']);
            console.log('response error', response);
            this.global.showError('delete',error.status,response.message);
            if (error.status == 401){
              this.global.forceLogout();
            }
        }); 
      }
    })    

  }

  getRowHeight(row) {
    // console.log('row',row);
    return 'auto';
  }

  getRowClass = (row) => {
    return {
      'row-color': row.active == 0
    };
  }

}
