import { Component, OnInit } from '@angular/core';
import { GLOBAL } from "../../providers/global";
import { LoaderService } from '../../shared/loader.service';
import { Http, Headers, RequestOptions } from '@angular/http';
// import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  oldPass = '';
  newPass = '';
  newPass2 = '';
  env = '';
  constructor(public global:GLOBAL, 
    public http : Http,
    public loaderService : LoaderService) { }

  ngOnInit() {
    // this.env = environment.product;
    this.global.onRefresh();
  }

  onCancel() {
    this.oldPass = '';
    this.newPass = '';
    this.newPass2 = '';
    this.global.goTo('content-layout');
  }

  onSave(){
    // this.global.showToast('success',"Berhasil","Anda berhasil masuk");
    this.global.showLog('info','Enter onSave','');
    if (this.oldPass.length < 1) {
        this.global.toastr.error("Kata kunci lama harus di isi.");
        return;
    }
    if (this.newPass.length < 1) {
        this.global.toastr.error("Kata kunci baru Harus di isi.");
        return;
    }

    if (this.newPass2.length < 1) {
        this.global.toastr.error("Kata kunci konfirmasi harus diisi.");
        return;
    }

    if (this.newPass2 != this.newPass) {
      this.global.toastr.error("Kata kunci barud dan kata kunci konfirmasi harus sama.");
      return;
    }

    this.loaderService.display(true);
    var sekarang = new Date().getTime();
    let link = this.global.my_api+'customers/change';
    // this.global.showLog('info','link',link);
    var headers = new Headers();        
    headers.append('Content-Type', 'application/json' );
    headers.append('x-access-token', window.localStorage.getItem('userToken') );
    let options = new RequestOptions({ headers: headers });
    let body = {
      "from_origin" : this.global.fromOrigin,
      "userServer" : window.localStorage.getItem('userServer'),
      "userId" : window.localStorage.getItem('userId'),
      "oldPass":this.oldPass,
      "newPass":this.newPass
    }
    // this.global.showLog('info','body',body);
    this.http.post(link, body, options)
    .subscribe(data => {
      this.loaderService.display(false);
      let response = JSON.parse(data['_body']);
      // console.log('body',response);
      if (response.status == 'OK'){
          this.global.toastr.info(response.message,"yeayy");
          this.global.forceLogout();
      }
      else {
        this.global.toastr.error(response.message,"oopss");
      }

    }, error => {
        let response = JSON.parse(error['_body']);
        this.loaderService.display(false);
        this.global.toastr.error(response.message,"oopss");
    });
  }

}
