import { Component, ViewContainerRef } from '@angular/core';
import { GLOBAL } from "./providers/global";
import { LoaderService } from './shared/loader.service';
import * as firebase from 'firebase/app';
import 'firebase/firestore';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    showLoader: boolean;
    constructor(public global : GLOBAL, 
        vRef: ViewContainerRef,
        private loaderService: LoaderService        
    ) { 
      }

    ngOnInit(){
        console.log('here in');
        this.loaderService.status.subscribe((val: boolean) => {
          this.showLoader = val;
        });
        const firebaseConfig = {
              // apiKey: "AIzaSyCLbqXS0umqjHxyUKL75V4dVdYDLERdvRs ",
              // authDomain: "my-app-a123z.firebaseapp.com",
              // projectId: "uruz-n",
              // storageBucket: "uruz-n.appspot.com"
              apiKey: "aaaaa",
              authDomain: "pot6client.firebaseapp.com",
              projectId: "estilosoft",
              storageBucket: "estilosoft.appspot.com"
        };
        firebase.initializeApp(firebaseConfig);
        firebase.firestore().settings({timestampsInSnapshots: true});
      }
}