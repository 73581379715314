import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { GLOBAL } from "../../providers/global";
import { LoaderService } from '../../shared/loader.service';
import swal from 'sweetalert2';
import { Http, Headers, RequestOptions } from '@angular/http';


@Component({
  selector: 'app-login',
  templateUrl: './home1.component.html',
  styleUrls: ['./home1.component.scss']
})
export class Home1Component {
  rows_cat = [];
  rows = [];
  rows_detail = [];
  // filter = [];
  hal = 1;
  more = false;
  more_cat = false;

  cat_selected = 'all';
  filter = {
    search : '',
    urut : 'm.name',
    brand_id : '%'
  }

  filter_cat = {
    search : '',
  }

  detailData = [];
  mode = 'list';
  constructor(public global:GLOBAL,
              public loaderService : LoaderService,
              public http : Http ) { }

    onLogout(){
      this.global.logout();
    }

    ngOnInit() {
      this.global.onRefresh();
      this.hal = 1;
      this.filter = {
        search : '',
        urut : 'm.name',
        brand_id : '%'
      }
      this.mode = 'category';
      this.refreshCat('new');
    }    

    onKeyUp(event: any){
      console.log('key up');
      if (event.keyCode == 13){
        this.refresh('new');
      }
    }

    onKeyUpCat(event: any){
      if (event.keyCode == 13){
        this.refreshCat('new');
      }
    }
    
    refreshCat(stat){
      // console.log('start refresh',this.filter);
      if (stat == 'new') {
        this.rows_cat = [];
        this.hal = 1;
      }
      else {
        this.hal++;
      }
      window.localStorage.setItem('product_cust_filter_cat',JSON.stringify(this.filter));
  
      this.loaderService.display(true);
      let link = this.global.my_api+'products/customer-category';
      var headers = new Headers();        
      headers.append('Content-Type', 'application/json' );
      headers.append('x-access-token', window.localStorage.getItem('userToken') );
      let options = new RequestOptions({ headers: headers });
      let body = {
        "from_origin" : this.global.fromOrigin,
        "userServer" : window.localStorage.getItem('userServer'),
        "userId" : window.localStorage.getItem('userId'),

        "search1" : this.filter_cat.search,
        "hal" : this.hal,
      }
      // this.global.showLog('info','body',body);
      this.http.post(link, body, options)
      .subscribe(data => {
        this.loaderService.display(false);
        let response = JSON.parse(data['_body']);
        // this.global.showLog('info','response',response);
        if (response.status == 'OK'){
          this.global.showLog('info','refresh','ok');

          if (response.data.length == 0 ){
            this.more_cat = false;
          }
          else {
            this.more_cat = true;
            let id_now = '';
            for (var i=0; i<response.data.length; i++){
              let bar = response.data[i];
                this.rows_cat.push(bar);  
            }
  
          }
        }
        else {
          // this.global.showLog('error','refresh',response);
          this.global.showError('refresh','00',response.message);
        }
  
      }, error => {
          this.loaderService.display(false);
          // this.global.showLog('error','error',error);
          let response = JSON.parse(error['_body']);
          this.global.showError('refresh',response.status,response.message);
          if (error.status == 401){
            this.global.forceLogout();
          }
      });
    }
  
    onClickCat(data){
      if (data == 'all') {
        this.cat_selected = 'all';
      }
      else {
        this.cat_selected = data.id;
      }
      this.mode = 'list';
      this.refresh('new');
    }


    refresh(stat){
      console.log('start refresh',this.filter);
      if (stat == 'new') {
        this.rows = [];
        this.hal = 1;
      }
      else {
        this.hal++;
      }
      window.localStorage.setItem('product_cust_filter',JSON.stringify(this.filter));
  
      this.loaderService.display(true);
      let link = this.global.my_api+'products/customer';
      var headers = new Headers();        
      headers.append('Content-Type', 'application/json' );
      headers.append('x-access-token', window.localStorage.getItem('userToken') );
      let options = new RequestOptions({ headers: headers });
      let body = {
        "from_origin" : this.global.fromOrigin,
        "userServer" : window.localStorage.getItem('userServer'),
        "userId" : window.localStorage.getItem('userId'),

        "search1" : this.filter.search,
        "urut" : this.filter.urut,
        "brand_id" : this.filter.brand_id,
        "category_id" : this.cat_selected,
        "hal" : this.hal,
        "use_color" : this.global.clientSetting['_client_use_product_color'] == 1 ? 1 : 0,
        "use_size" : this.global.clientSetting['_client_use_product_size'] == 1 ? 1 : 0,
  
      }
      // this.global.showLog('info','body',body);
      this.http.post(link, body, options)
      .subscribe(data => {
        this.loaderService.display(false);
        let response = JSON.parse(data['_body']);
        this.global.showLog('info','response',response);
        if (response.status == 'OK'){
          this.global.showLog('info','refresh','ok');

          if (response.data.length == 0 ){
            this.more = false;
          }
          else {
            this.more = true;
            let id_now = '';
            for (var i=0; i<response.data.length; i++){
              let bar = response.data[i];
              if (bar.id_saja != id_now) {
                this.rows.push(bar);
                id_now = bar.id_saja;
              }
  
            }
  
          }
        }
        else {
          // this.global.showLog('error','refresh',response);
          this.global.showError('refresh','00',response.message);
        }
  
      }, error => {
          this.loaderService.display(false);
          // this.global.showLog('error','error',error);
          let response = JSON.parse(error['_body']);
          this.global.showError('refresh',response.status,response.message);
          if (error.status == 401){
            this.global.forceLogout();
          }
      });
    }
  
    onClick(data){
      if (data.id_saja == data.warna && data.warna == data.size && data.id_saja == data.size){
        this.rows_detail = [];
        this.mode = 'detail';
        this.detailData = data;
        this.detailData['note']='';
        this.detailData['qty_now']=this.detailData['qty_min_bulk'];
        // console.log('data',data);
        console.log('this.detailData',this.detailData);
      }
      else {
        // ambil detail nya
        this.loaderService.display(true);
        let link = this.global.my_api+'products/customer';
        var headers = new Headers();        
        headers.append('Content-Type', 'application/json' );
        headers.append('x-access-token', window.localStorage.getItem('userToken') );
        let options = new RequestOptions({ headers: headers });
        let body = {
          "from_origin" : this.global.fromOrigin,
          "userServer" : window.localStorage.getItem('userServer'),
          "userId" : window.localStorage.getItem('userId'),
  
          "search1" : data.id_saja,
          "urut" : 'm.id',
          "brand_id" : '%',
          "hal" : 1,
          "use_color" : this.global.clientSetting['_client_use_product_color'] == 1 ? 1 : 0,
          "use_size" : this.global.clientSetting['_client_use_product_size'] == 1 ? 1 : 0,
    
        }
        // this.global.showLog('info','body',body);
        this.http.post(link, body, options)
        .subscribe(data => {
          this.loaderService.display(false);
          let response = JSON.parse(data['_body']);
          this.global.showLog('info','response',response);
          if (response.status == 'OK'){
            // this.global.showLog('info','refresh','ok');
            this.rows_detail = [];
            this.rows_detail = response.data;

            this.detailData = this.rows_detail[0];
            this.mode = 'detail';
            this.detailData['note']='';
            this.detailData['qty_now']=this.detailData['qty_min_bulk'];
    
          }
          else {
            // this.global.showLog('error','refresh',response);
            this.global.showError('refresh','00',response.message);
          }
    
        }, error => {
            this.loaderService.display(false);
            // this.global.showLog('error','error',error);
            let response = JSON.parse(error['_body']);
            this.global.showError('refresh',response.status,response.message);
            if (error.status == 401){
              this.global.forceLogout();
            }
        });        
      }
    }

    onSmall(x){
      this.detailData = x;
      // this.detailData['picpath'] = this.detailData['picpath1'];
    }

    onSmallNumber(x){
      console.log(x);
      if (x==1){
        this.detailData['picpath'] = this.detailData['picpath1'];
      }
      else if (x==2){
        console.log('sini');
        this.detailData['picpath'] = this.detailData['picpath2'];
      }
      else if (x==3){
        this.detailData['picpath'] = this.detailData['picpath3'];
      }
      else if (x==4){
        this.detailData['picpath'] = this.detailData['picpath4'];
      }
      else if (x==5){
        this.detailData['picpath'] = this.detailData['picpath5'];
      }
      else if (x==6){
        this.detailData['picpath'] = this.detailData['picpath6'];
      }
      else if (x==7){
        this.detailData['picpath'] = this.detailData['picpath7'];
      }
      else if (x==8){
        this.detailData['picpath'] = this.detailData['picpath8'];
      }
    }

    changeQty(x){
      if (x == 'min') {
        this.detailData['qty_now']--;
      }
      else {
        this.detailData['qty_now']++;
      }
    }

    addToCart(w,qty,note){
      // if (x == 'detail') {
      //   console.log('nanti add to cart : ',x);        
      // }
      // else {
      //   console.log('nanti add to cart : ',x);
      // }
      if (this.mode == 'detail') {
        this.lanjutAddToCart(w,qty,note);
      }
      else {
        if (w.id_saja == w.warna && w.warna == w.size && w.id_saja == w.size){
          this.lanjutAddToCart(w,qty,note);
        }
        else {
          this.onClick(w);
        }
      }

    }

    lanjutAddToCart(w,qty,note){
      let addNew = true;
      for (let i=0; i<this.global.cartData.length; i++){
        if (this.global.cartData[i].id_saja == w.id_saja ){
          this.global.cartData[i].qty++;
          addNew = false;
          this.global.toastr.info(w.product_name +' sudah di tambahkan ke keranjang.');
          break;
        }
      }

      if (addNew){
          this.global.cartData.push({
            warehouse_id : this.global.defaultWarehouseId,
            warehouse_name : this.global.defaultWarehouseId,
            product_id : w.product_id,
            product_name : w.product_name,
            id_saja : w.id_saja,
            warna : w.warna,
            size : w.size,
            unit_id : w.unit_id,
            unit_id_small : w.unit_id_small,
            qty_unit : w.qty_unit,
            detail_note : '',

            curr : 'IDR',
            rate : 1,

            detail_disc1 : 0,
            detail_disc2 : 0,
            detail_disc_value : 0,
            total :0,

            coa_inv : w.coa_inv,
            coa_inv_name : w.coa_inv,
            coa_sales : w.coa_sales,
            coa_sales_name : w.coa_sales,

            description : w.description,
            price : w.price_bulk,
            qty : qty,
            note : note,
            qty_min_bulk : w.qty_min_bulk,
            length_packing : w.length_packing,
            width_packing : w.width_packing,
            height_packing : w.height_packing,
            weight_packing : w.weight_packing,
            picpath_thumb : w.picpath_thumb
          })
          this.global.toastr.info(w.product_name +' sudah di tambahkan ke keranjang.');
      }

      this.global.hitungCart();
    }

    closeDetail(){
      this.mode = 'list';
    }

}
