import { Component, AfterViewChecked } from '@angular/core';
import { GLOBAL } from "../../providers/global";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked{

    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;

    constructor(public global:GLOBAL,
        ) { }

    ngAfterViewChecked() {

        setTimeout(() => {
            var wrapperDiv = document.getElementsByClassName("wrapper")[0];
            var dir = wrapperDiv.getAttribute("dir");
            if (dir === 'rtl') {
                this.placement = 'bottom-left';
            }
            else if (dir === 'ltr') {
                this.placement = 'bottom-right';
            }
        }, 3000);


    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    onLogout(){
        this.global.logout();
      }
  

    changeQty(i,act){
        if (act=='plus'){
            this.global.cartData[i].qty++;
        }
        else {
            if (this.global.cartData[i].qty<=this.global.cartData[i].qty_min_bulk){
                this.global.toastr.error('Minimum pembelian '+this.global.cartData[i].name +' adalah '+this.global.cartData[i].qty_min_bulk);
            }
            else {
                this.global.cartData[i].qty++;
            }
        }
    }
}
