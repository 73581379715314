
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { HttpModule } from '@angular/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModalModule } from  '@ng-bootstrap/ng-bootstrap';


import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';

import * as $ from 'jquery';
import { GLOBAL } from "./providers/global";
import { LoaderService } from './shared/loader.service';
import { MyFocusDirective } from "./providers/app.myfocusdirective";

import { LoginPageComponent } from './pages/login/login-page.component';
import { Home1Component } from './pages/home1/home1.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { MyTransactionComponent } from './pages/my-transaction/my-transaction.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';




@NgModule({
    declarations: [
        MyFocusDirective,
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        LoginPageComponent,
        Home1Component,
        UserPageComponent,
        ProductComponent,
        ProductDetailComponent,
        CheckoutComponent,
        MyTransactionComponent,
        ChangePasswordComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        NgbModule.forRoot(),
        HttpModule,
        NgxDatatableModule,
        NgbModalModule,
        FormsModule,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        SweetAlert2Module.forRoot({
            buttonsStyling: false,
            customClass: 'modal-content',
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn'
        })

    ],
    providers: [
        AuthService,
        AuthGuard,
        GLOBAL,
        LoaderService,

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }