import {Injectable} from "@angular/core";
import * as firebase from 'firebase';

import {Router} from "@angular/router";
import { Http, Headers, RequestOptions } from '@angular/http';
import { LoaderService } from '../shared/loader.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

declare var $:any;

@Injectable()
export class GLOBAL {
    public my_api = 'http://localhost:3000/';
    public baseURL = window.location.origin
    public user_server   = '';
    public fromOrigin    = "*";
    public clientSetting = '';
    public clientSettingObj = {}
    public appsSetting   = []
    public my_api_pic    = '';
    public isLoggedIn    = false;

    public userDisc = 0;
    public userDiscCoa = '';
    public defaultWarehouseId = 'P-W1';
    public defaultBranchId = 'PUSAT';

    public maxPhotoSize = 1024 * 1024 * 1;
    public maxPhotoSizeInfo = '1MB';
    public clientSettingUniqueId = ''
    public clientLogoLink = ''

    public cartData = [];
    public cartTotal = 0;
    public cartTotalDiskon = 0;
    public cartGrandTotal = 0;
    public cartTotalWeight = 0;
    public cartTotalDimension = 0;
    public cartNote = '';

    constructor(private router: Router, 
        public toastr: ToastrService,
        public http:Http,
        public loaderService : LoaderService
    ) { 
    }
    
    public clearCart(){
      this.cartData = [];
      this.cartTotal = 0;
    }

    public hitungCart(){
      this.cartTotal = 0;
      this.cartTotalWeight = 0;
      this.cartTotalDimension = 0;
      this.cartData.forEach(bar=>{
        this.cartTotal += bar.qty * bar.price;
        this.cartTotalWeight += bar.qty * bar.weight_packing;
        this.cartTotalDimension += bar.qty * bar.length_packing * bar.width_packing * bar.height_packing;
      });
      this.cartTotalDiskon = (this.userDisc / 100 * this.cartTotal);
      this.cartGrandTotal = this.cartTotal - this.cartTotalDiskon;
      window.localStorage.setItem('cartData',JSON.stringify(this.cartData));      
    }

    public goTo(where){
          this.router.navigate([where]);
    }


    public prepareClientSetting(){
        this.clientSetting = '';
        this.appsSetting = [];

        var cSetting = JSON.parse(window.localStorage.getItem('clientSetting'));
        cSetting.forEach(row=>{
            if (row.id.startsWith("_apps")){
                this.appsSetting[row.id] = row.value;
            }
        })
        this.my_api_pic = this.appsSetting['_apps_upload_use_s3'] ? this.appsSetting['_apps_upload_use_s3']  : window.localStorage.getItem('my_api')
        this.showLog('info','clientSetting',this.clientSetting);
        this.showLog('info','appsSetting',this.appsSetting);
        // this.userId = window.localStorage.getItem('userId');
        // this.userName = window.localStorage.getItem('userName');
        // this.userEmail = window.localStorage.getItem('userEmail');
        // this.userBranch = window.localStorage.getItem('userBranch');
        // this.userGroup = window.localStorage.getItem('userGroup');
    }


    public onRefresh(){
      if (window.localStorage.getItem('isLoggedIn') == 'true') {
          if (this.clientSetting.length || this.appsSetting.length ){
              this.prepareClientSetting();
          }

          this.user_server = window.localStorage.getItem('userServer');
          this.my_api = window.localStorage.getItem('my_api'); 
          this.userDisc = parseFloat(window.localStorage.getItem('userDisc'));          
          this.userDiscCoa = window.localStorage.getItem('userDiscCoa');     
          this.clientSetting = window.localStorage.getItem('clientSetting');
          for(var i = 0; i < JSON.parse(this.clientSetting).length; i++) {
              let item = JSON.parse(this.clientSetting)[i]
              this.clientSettingObj[item.id] = item
          }
          // console.log(this.clientSettingObj['_client_use_client_page_show_0'])
          this.isLoggedIn = window.localStorage.getItem('isLoggedIn') == 'true' ? true : false;
          if (window.localStorage.getItem('cartData')){
            this.cartData = JSON.parse(window.localStorage.getItem('cartData'));
            this.hitungCart();
          }
          else {
            this.cartData = [];
          }

      }
      else {
        swal({
          title: 'PERHATIAN',
          text: "ID ini sudah keluar, silahkan login ulang",
          type: 'error',
          // showCancelButton: true,
          confirmButtonColor: '#3085d6',
          // cancelButtonColor: '#d33',
          confirmButtonText: 'Login',
          // cancelButtonText: 'Tidak'
        })      
        .then((result)=>{
          this.goTo('login');
        })
      }
  }   


    public logout(){
        swal({
            title: 'KONFIRMASI',
            text: "Yakin mau keluar ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yakin',
            cancelButtonText: 'Tidak'
          }).then((result) => {
            if (result.value) {
                this.forceLogout();
            }
          })    
    }

    public forceLogout() {
      this.loaderService.display(true);
      var sekarang = new Date().getTime();
      let link = this.my_api+'customers/logout';

      var headers = new Headers();        
      headers.append('Content-Type', 'application/json' );
      let options = new RequestOptions({ headers: headers });
      let body = {
        "from_origin" : this.fromOrigin,
        "userServer" : window.localStorage.getItem('userServer'),
        "userId" : window.localStorage.getItem('userId'),
      }
      // this.showLog('info','body',body);
      this.http.post(link, body, options)
      .subscribe(data => {
        this.loaderService.display(false);
        let response = JSON.parse(data['_body']);
        console.log('body',response);
        window.localStorage.clear();
        window.localStorage.setItem('isLoggedIn', 'false');
        this.isLoggedIn = false;
        if (response.status == 'OK'){
          this.router.navigate(['login']);
        }
        else {
          this.toastr.error(response.message);
          this.router.navigate(['login']);
        }

      }, error => {
          window.localStorage.clear();
          window.localStorage.setItem('isLoggedIn', 'false');
          this.isLoggedIn = false;
          
          this.loaderService.display(false);
          let response = JSON.parse(error['_body']);
          this.showLog('error','response',response);
          this.toastr.error(response.message);
          this.router.navigate(['login']);
      });
  }

      
    public showLog(s,a,b){
      console.log(s+', '+a+' : ',b);
    }

    public sekarangToSave(){
        var waktu = new Date();

        return waktu.getFullYear()+'-'+
        (waktu.getMonth()<10?'0'+(waktu.getMonth()+1):waktu.getMonth()+1)+'-'+
        (waktu.getDate()<10?'0'+waktu.getDate():waktu.getDate());    
    }

    public msToDateShow(waktu){
        // return waktu;
        // console.log('waktu : ',waktu);
        waktu = new Date(waktu);
        // console.log('waktu sesudah : ',waktu);

        return (waktu.getDate()<10?'0'+waktu.getDate():waktu.getDate())+'-'+
        (waktu.getMonth()<10?'0'+(waktu.getMonth()+1):waktu.getMonth()+1)+'-'+
        waktu.getFullYear()+' '+
        (waktu.getHours()<10?'0'+waktu.getHours():waktu.getHours())+':'+
        (waktu.getMinutes()<10?'0'+waktu.getMinutes():waktu.getMinutes());    
    }

    public msToDateSave(waktu){
      // return waktu;
      // console.log('waktu : ',waktu);
      waktu = new Date(waktu);
      // console.log('waktu sesudah : ',waktu);

      return waktu.getFullYear()+'-'+
      (waktu.getMonth()<10?'0'+(waktu.getMonth()+1):waktu.getMonth()+1)+'-'+
      (waktu.getDate()<10?'0'+waktu.getDate():waktu.getDate())+' '+
      (waktu.getHours()<10?'0'+waktu.getHours():waktu.getHours())+':'+
      (waktu.getMinutes()<10?'0'+waktu.getMinutes():waktu.getMinutes())+':'+   
      (waktu.getSeconds()<10?'0'+waktu.getSeconds():waktu.getSeconds());    
  }

    public getDateDiff(startDate, endDate) {
    // var startDate = '2019-03-30 15:10:10';
    // var endDate = '2019-03-10 22:30:00';
      var diff = endDate.getTime() - startDate.getTime();
      var days = Math.floor(diff / (60 * 60 * 24 * 1000));
      var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
      var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
      var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
      return { day: days, hour: hours, minute: minutes, second: seconds };
    }    

    public showError(what, errorCode, why){
        if (errorCode == 423){
            swal({
                title: 'Ooopss',
                text: why,
                type: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'TUTUP',
            })        
        }
        else {
            var textnya = '';
            if (what.startsWith('Proses transaksi si, id :')){
              textnya = why;
            }
            else if (why.startsWith('Error master detail new STOK ID PRODUK :')) {
              textnya = why.split('sql:')[0];
            }
            else {
              textnya = "Error "+what+" (error Code : "+errorCode+"), karena : "+why
            }
            swal({
                title: 'Ooopss',
                // text: "Error "+what+" (error Code : "+errorCode+"), karena : "+why,
                text: textnya,
                type: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'TUTUP',
            })        
        }
    }

    public showInfo(msg){
      swal({
          title: 'INFO',
          // text: "Error "+what+" (error Code : "+errorCode+"), karena : "+why,
          text: msg,
          type: 'info',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'TUTUP',
      })        
  }    

    public sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    text_truncate = function(str, length) {
      if (length == null) {
        length = 100;
      }
      if (str.length > length) {
        return str.substring(0, length);
      } else {
        return str;
      }
    };


    public process(transCode, transId, so_id):Promise<any>{
      return new Promise ((resolve,reject)=>{
          try {
              this.loaderService.display(true);
              var sekarang = new Date().getTime();
              let link = this.my_api+'process';
      
              var headers = new Headers();        
              headers.append('Content-Type', 'application/json' );
              headers.append('x-access-token', window.localStorage.getItem('userToken') );
              let options = new RequestOptions({ headers: headers });
              let body = {
                "from_origin" : this.fromOrigin,
                "userServer" : window.localStorage.getItem('userServer'),
                "userId" : window.localStorage.getItem('userId'),
                "transCode" : transCode,
                "so_id": so_id,
                "transId" : transId,
                "is_customer" : 1
              }
              // this.showLog('info','body',body);
              this.http.post(link, body, options)
              .subscribe(data => {
                this.loaderService.display(false);
                let response = JSON.parse(data['_body']);
                // console.log('body',response);
                if (response.status == 'OK'){
                  // this.showLog('info','proses transaksi','ok');
                  this.toastr.info('Proses transaksi '+transCode+', id : '+transId+' berhasil di proses');
                  resolve(true);
                }
                else {
                  this.showLog('error','proses transaksi',response);
                  this.showError('Proses transaksi '+transCode+', id : '+transId,'00',response.message);

                  reject(false);
                }
      
              }, error => {
                  this.loaderService.display(false);
                  let response = JSON.parse(error['_body']);
                  this.showError('Proses transaksi '+transCode+', id : '+transId,error.status,response.message);
                  if (error.status == 401){
                    this.forceLogout();
                  }
                  reject(false);
              });
          }
          catch (error){
            reject(error);
          }
      })
  }


}