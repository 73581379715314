import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { GLOBAL } from "../../providers/global";
import { LoaderService } from '../../shared/loader.service';
import swal from 'sweetalert2';
import { Http, Headers, RequestOptions } from '@angular/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  trans_date_input : any;

  rows = [];
  kirim = '';
  detailData = {
    id : '',
    code : '',
    trans_date : '',
    so_date : '',
    no_faktur_pajak : '',

    branch_id : '',
    branch_name : '',
    branch_picpath : '',
    
    customer_id : '',
    customer_name : '',
    customer_address : '',
    customer_npwp : '',
    marketing_id : '',
    marketing_name : '',
    table_id : '',
    note : '',
    po_customer : '',
    jumlah_tamu : 0,

    default_warehouse_id : '',
    default_warehouse_name : '',

    branch_id_pickup : '',
    branch_name_pickup : '',

    delivery_receiver_name : '',
    delivery_receiver_phone : '',
    delivery_receiver_address : '',

    discounts_note : [],
    discounts_value : 0,
    charges_note : [],
    charges_value : 0,
    charges_value_non_pb1 : 0,
    charges_value_pb1 : 0,

    total : 0,
    total_weight : 0,
    total_dimension : 0,
    kembali : 0,

    payment_type : 0,
    top : 0,
    payment_type_show : '',

    payment_cash_value : 0,
    payment_cash_coa : '1.01.01.01.01',
    payment_cash_coa_name : '',

    payment_others_edc : '',
    payment_others_coa : '',
    payment_others_coa_name : '',
    payment_others_value : 0,
    payment_others_note : '',
    
    payment_dc1_edc : '',
    payment_dc1_coa : '',
    payment_dc1_coa_name : '',
    payment_dc1_value : 0,
    payment_dc1_note : '',

    payment_dc2_edc : '',
    payment_dc2_coa : '',
    payment_dc2_coa_name : '',
    payment_dc2_value : 0,
    payment_dc2_note : '',
    
    payment_cc1_edc : '',
    payment_cc1_coa : '',
    payment_cc1_coa_name : '',
    payment_cc1_value : 0,
    payment_cc1_note : '',
    
    payment_cc2_edc : '',
    payment_cc2_coa : '',
    payment_cc2_coa_name : '',
    payment_cc2_value : 0,
    payment_cc2_note : '',
    
    dp_so : 0,
    coa_dp_so : '',
    coa_dp_so_name : '',

    coa_dp_so_tax : '-',
    coa_dp_so_tax_name : '-',

    coa_ar_customer : window.localStorage.getItem('userCoa'),
    coa_ar_customer_name : '',
    coa_sales : '',
    coa_sales_name : '',

    trans_status : 0,
    trans_status_show : '',
    
    created_by : '',
    created_by_name : '',
    created_date : '',

    updated_by : '-',
    updated_by_name : '',
    updated_date : '',

    processed_by : '-',
    processed_by_name : '',
    processed_date : '',

    void_by : '-',
    void_by_name : '',
    void_note : '',
    void_date : '',

    is_prepared : 0,
    is_prepared_show : '',
    prepared_by : '',
    prepared_by_name : '',
    prepared_note : '',

    picpath : '',
    print_x_times : 0,

    so_id : '',

    is_checked : 0,
    is_checked_show : '',
    checked_by : '',
    checked_by_name : '',
    checked_note : '',

    posted_inv_status : 0,
    posted_inv_note : '',
    posted_inv_date : '',
    posted_acc_status : 0,
    posted_acc_note : '',
    posted_acc_date : '',

  }

  constructor(public global:GLOBAL, 
    public http : Http,
    private modalService: NgbModal,
    public loaderService : LoaderService) { }

    ngOnInit() {
      this.global.onRefresh();
      this.refresh();
      this.kirim = 'PICKUP SENDIRI';
    }
    
  
    refresh(){
      var sekarang= new Date();
      this.trans_date_input = {year: sekarang.getFullYear(), month: sekarang.getMonth() + 1, day: sekarang.getDate()};

      this.detailData = {
        id : UUID.UUID(),
        code : '',
        trans_date : '',
        so_date : '',
        no_faktur_pajak : '',
    
        branch_id : 'PUSAT',
        branch_name : 'PUSAT',
        branch_picpath : '',
        customer_id : window.localStorage.getItem('userId'),
        customer_name : window.localStorage.getItem('userName'),
        customer_address : '',
        customer_npwp : '',
        marketing_id : window.localStorage.getItem('marketingId'),
        marketing_name : window.localStorage.getItem('marketingId'),
        table_id : '',
        jumlah_tamu : 0,
        note : '',
        po_customer : '',

        default_warehouse_id : 'PW-1',
        default_warehouse_name : 'PW-1',
            
        branch_id_pickup : '',
        branch_name_pickup : '',
    
        delivery_receiver_name : '',
        delivery_receiver_phone : '',
        delivery_receiver_address : '',
    
        discounts_note : [],
        discounts_value : 0,
        charges_note : [],
        charges_value : 0,
        charges_value_non_pb1 : 0,
        charges_value_pb1 : 0,
    
        total : 0,
        total_weight : 0,
        total_dimension : 0,
        kembali : 0,
        
        payment_type : 5,
        top : 0,
        payment_type_show : '',
    
        payment_cash_value : 0,
        payment_cash_coa : '',
        payment_cash_coa_name : '',
    
        payment_others_edc : '',
        payment_others_coa : '',
        payment_others_coa_name : '',
        payment_others_value : 0,
        payment_others_note : '',
        
        payment_dc1_edc : '',
        payment_dc1_coa : '',
        payment_dc1_coa_name : '',
        payment_dc1_value : 0,
        payment_dc1_note : '',
    
        payment_dc2_edc : '',
        payment_dc2_coa : '',
        payment_dc2_coa_name : '',
        payment_dc2_value : 0,
        payment_dc2_note : '',
        
        payment_cc1_edc : '',
        payment_cc1_coa : '',
        payment_cc1_coa_name : '',
        payment_cc1_value : 0,
        payment_cc1_note : '',
        
        payment_cc2_edc : '',
        payment_cc2_coa : '',
        payment_cc2_coa_name : '',
        payment_cc2_value : 0,
        payment_cc2_note : '',
        
        dp_so : 0,
        coa_dp_so : '',
        coa_dp_so_name : '',
        coa_dp_so_tax : '-',
        coa_dp_so_tax_name : '-',
        
        coa_ar_customer : window.localStorage.getItem('userCoa'),
        coa_ar_customer_name : '',
        coa_sales : '',
        coa_sales_name : '',
    
        trans_status : 0,
        trans_status_show : '',
        
        created_by : '',
        created_by_name : '',
        created_date : '',
    
        updated_by : '-',
        updated_by_name : '',
        updated_date : '',
    
        processed_by : '-',
        processed_by_name : '',
        processed_date : '',
    
        void_by : '-',
        void_by_name : '',
        void_note : '',
        void_date : '',
    
        is_prepared : 0,
        is_prepared_show : '',
        prepared_by : '',
        prepared_by_name : '',
        prepared_note : '',
    
        picpath : '',
        print_x_times : 0,
    
        so_id : '',
    
        is_checked : 0,
        is_checked_show : '',
        checked_by : '',
        checked_by_name : '',
        checked_note : '',
    
        posted_inv_status : 0,
        posted_inv_note : '',
        posted_inv_date : '',
        posted_acc_status : 0,
        posted_acc_note : '',
        posted_acc_date : '',
    
      }
      this.detailData.discounts_note.push({
        id : 'disc',
        percent_value : this.global.userDisc,
        min_value : 0,
        max_value : 0,
        value : 0,
        coa : this.global.userDiscCoa
      });

      if (this.global.cartData.length <= 0){
        this.global.toastr.warning("belum ada produk yang di pilih.");
        this.global.goTo('content-layout');
      }
      else {
        this.global.hitungCart();
      }
    }
      
    deleteRow(i){
      swal({
        title: 'KONFIRMASI',
        text: "Yakin mau hapus "+this.global.cartData[i].product_name+"?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Tidak'
      }).then((result) => {
        if (result.value) {
            this.global.cartData.splice(i,1);
            this.global.cartData = [...this.global.cartData];
            this.global.hitungCart();
        }
      })    
    }
      

    actTemp(x,i){
      if (x=='plus'){
        this.global.cartData[i].qty++;
      }
      else if (x=='min'){
        if (this.global.cartData[i]['qty'] > this.global.cartData[i]['qty_min_bulk']) {
          this.global.cartData[i]['qty']--;
        }
        else {
          this.global.toastr.error('qty sudah tidak bisa di kurangi lagi');
        }
      } 
      this.global.cartData = [...this.global.cartData];
      this.hitungTotal();
    }

    hitungTotal():Promise<any>{
      console.log('masuk hitung total');
      return new Promise ((resolve,reject)=>{
        try {
          this.detailData.total = 0;
          this.global.cartData.forEach(baris=>{
            this.detailData.total += baris.qty * baris.price;
            this.detailData.total_weight += baris.qty * baris.weight_packing;
            this.detailData.total_dimension += baris.qty * baris.length_packing * baris.width_packing * baris.height_packing;
          });
          this.detailData.discounts_value = 0;
          this.detailData.discounts_note.forEach(d=>{
            if (this.detailData.total < d.min_value) {
              this.global.toastr.error('Minimal total sebelum pajak adalah '+d.min_value,'Tidak bisa diskon');
              return reject(false);
            }
            else if ( (this.detailData.total > d.max_value) && (d.max_value > 0) ){
              // d.value = d.max_value;
              d.value = d.percent_value / 100 * d.max_value;
              this.detailData.discounts_value += d.value; 
            }
            else {
              d.value = (d.percent_value / 100 * this.detailData.total);
              this.detailData.discounts_value += d.value; 
            }
          })
  
          resolve(true);
        }
        catch (error){
          reject(error);
        }
      })
    }

    onCancel(stat){
      if (stat == 'pending') {
        this.global.goTo('content-layout')
      }
      else {
        this.global.cartTotal = 0;
        this.global.cartData = [];
        window.localStorage.removeItem('cartData');
        this.global.cartData = [...this.global.cartData];
        this.global.goTo('content-layout')
      }
    }    


    onSave(){
      if (this.global.cartData.length <= 0){
        this.global.toastr.error("Belum ada belanjaan","JANGAN CHECKOUT DULU");
        return;
      }
  
      this.hitungTotal()
      .then(lanjut=>{
        this.detailData.trans_date = this.trans_date_input.year+"-"+this.trans_date_input.month+"-"+this.trans_date_input.day;
        // this.detailData.estimation_date = this.estimation_date_input.year+"-"+this.estimation_date_input.month+"-"+this.estimation_date_input.day;

        this.detailData.note += " kirim dengan : "+this.kirim;

        this.loaderService.display(true);
        this.global.showLog('info','masuk','save new');
        let link = this.global.my_api+'trans-s-si/save';
  
        var headers = new Headers();        
        headers.append('Content-Type', 'application/json' );
        headers.append('x-access-token', window.localStorage.getItem('userToken') );
        let options = new RequestOptions({ headers: headers });
        let body = {
          "from_origin" : this.global.fromOrigin,
          "userServer" : window.localStorage.getItem('userServer'),
          "userId" : window.localStorage.getItem('userId'),
          "transMode" : 'new',
          "detailData" : JSON.stringify(this.detailData),
          "detailTrans" : JSON.stringify(this.global.cartData),
          "discountsNote" : JSON.stringify(this.detailData.discounts_note),
          "chargesNote" : JSON.stringify([]),
          "is_customer" : 1
    
        }
        this.global.showLog('info','body',body);
        this.http.post(link, body, options)
        .subscribe(data => {
          this.loaderService.display(false);
          let response = JSON.parse(data['_body']);
          if (response.status == 'OK'){
            // console.log('reponse', response);
            this.global.showLog('info','save new','ok');
            this.global.toastr.info('Data berhasil di simpan');
              this.global.process('si',this.detailData.id, this.detailData.so_id)
              .then(result=>{
                // this.onCancel();
                this.refresh();
                this.global.cartTotal= 0;
                this.global.cartData = [];
                window.localStorage.removeItem('cartData');

                this.global.goTo('my-transaction');
                this.global.showInfo("Terima kasih telah belanja di vento."+"\n"+
                                     "Silahkan kirim bukti transfer ke admin kami."+
                                     "Jangan lupa tulis tanggal dan nomor invoice SI-XXXX("+this.detailData.id.slice(0,7)+")."+
                                     "Pesanan anda akan di proses oleh admin secepat nya")
              })
              .catch(error=>{
                console.log('error',error.message);
                this.global.goTo('my-transaction')
                // this.onCancel();
                // this.refresh();
              })
          }
          else {
            this.global.showLog('error','save new',response);
            this.global.showError('Simpan Data','00',response.message);
          }
    
        }, error => {
            this.loaderService.display(false);
            let response = JSON.parse(error['_body']);
    
            this.global.showError('Simpan Data',error.status,response.message);
            if (error.status == 401){
              this.global.forceLogout();
            }
        }); 
      })
      .catch(err=>{
        this.global.showError('Error hitung total',err.status,'');
      })
  
    }    
  
    // updateValue(event, cell, rowIndex) {
    //   console.log('inline editing rowIndex', rowIndex)
    //   this.editing[rowIndex + '-' + cell] = false;
    //   this.detailTrans[rowIndex][cell] = event.target.value;
    //   if (cell == 'price'){
    //     this.detailTrans[rowIndex].price_curr = this.detailTrans[rowIndex].price / this.detailTrans[rowIndex].rate;
    //   }
    //   else if (cell == 'price_curr'){
    //     this.detailTrans[rowIndex].price = this.detailTrans[rowIndex].price_curr * this.detailTrans[rowIndex].rate;
    //   }
    //   this.detailTrans = [...this.detailTrans];
    //   this.hitungTotal();
    //   // console.log('UPDATED!', this.detailTrans[rowIndex][cell]);
    //   // console.log('detailTrans',this.detailTrans);
    // }
    
    // deleteRow(i){
    //   this.detailTrans.splice(i,1);
    //   this.detailTrans = [...this.detailTrans];
    // }
  

  


  



  




    // onAction(act,i){
    //   if (i.length > 20){
    //     var id_to_send = i;
    //   }
    //   else {
    //     var id_to_send = this.rows[i].id;
    //   }
  
    //   const modalRef = this.modalService.open(ActionModalComponent,{size : 'lg'});
    //   modalRef.componentInstance.name = {
    //     transCode : 'si',
    //     act : act,
    //     id : id_to_send
    //   };
    //   modalRef.result.then((result) => {
    //     this.global.showLog('info','result action',result);
    //     if (result.status == 'ok'){
    //       if (act == 'void'){
    //         if (this.global.userPriviledge['TRANS_SI_VOID'] != 1){
    //           var catatan_void = result.catatan;
    //           const modalRef = this.modalService.open(AskPasswordComponent,{size : 'sm'});
    //           modalRef.componentInstance.isHybrid = false;
    //           modalRef.componentInstance.tujuan = {
    //             id : 'void-transaksi',
    //             pri_to_check : 'TRANS_SI_VOID'
    //           };
          
    //           modalRef.result.then((result) => {
    //             if (result.lanjut){
    //               // lanjut void
    //               this.global.voidSpv('si',id_to_send, '', catatan_void, result.spv_user_id)
    //               .then(hasil=>{
    //                 if (hasil){
    //                   this.refresh();
    //                   console.log('hmmm 1');
    //                 }
    //               })
    //               .catch(error=>{
    //                 console.log('error',error.message);
    //               })
        
    //             }
    //           }, (reason) => {
    //             console.log('di dismiss, return nya ',reason);
    //           });
    //         }
    //         else {
    //           this.global.void('si',id_to_send, '', result.catatan)
    //           .then(hasil=>{
    //             if (hasil){
    //               this.refresh();
    //               console.log('hmmm 1');
    //             }
    //           })
    //           .catch(error=>{
    //             console.log('error',error.message);
    //           })
    //         }
    //       }
    //       else {
    //         this.global.action('si', act, result.catatan, id_to_send)
    //         .then(result=>{
    //           this.refresh();
    //         })
    //         .catch(error=>{
    //           console.log('error',error.message);
    //         })
    //       }
    //     }
    //   }, (reason) => {
    //     console.log('di dismiss, return nya ',reason);
    //   });
    // }

}
