import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from '../../pages/login/login-page.component';
import { Home1Component } from '../../pages/home1/home1.component';
import { ProductDetailComponent } from '../../pages/product-detail/product-detail.component';
import { CheckoutComponent } from '../../pages/checkout/checkout.component';
import { MyTransactionComponent } from '../../pages/my-transaction/my-transaction.component';
import { ChangePasswordComponent } from '../../pages/change-password/change-password.component';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'content-layout',
        loadChildren: './pages/content-layout-page/content-pages.module#ContentPagesModule'
    },
    // {
    //     path: 'login',
    //     loadChildren: './pages/content-layout-page/content-pages.module#ContentPagesModule'
    // },

    { path: 'login', component: LoginPageComponent },
    { path: 'home1', component: Home1Component },
    { path: 'product-detail', component: ProductDetailComponent },
    { path: 'checkout', component: CheckoutComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: 'my-transaction', component: MyTransactionComponent },

];