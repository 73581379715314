import {Input, Directive, OnInit, ElementRef, Renderer} from '@angular/core';

@Directive({ selector: '[myFocus]' })
export class MyFocusDirective implements OnInit {
 
  @Input('myFocus') isFocused: boolean;
 
  constructor(private hostElement: ElementRef, private renderer: Renderer) {}
 
  ngOnInit() {
    if (this.isFocused) {
      this.renderer.invokeElementMethod(this.hostElement.nativeElement, 'focus');
    }
  }
}