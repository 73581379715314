import { Routes, RouterModule } from '@angular/router';
import { UserPageComponent } from '../../pages/user-page/user-page.component';
import { ProductComponent } from '../../pages/product/product.component';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  { path: 'user', component: UserPageComponent },
  { path: 'beli-produk', component: ProductComponent },
  {
    path: 'changelog',
    loadChildren: './changelog/changelog.module#ChangeLogModule'
  },
  {
    path: 'full-layout',
    loadChildren: './pages/full-layout-page/full-pages.module#FullPagesModule'
  },

];